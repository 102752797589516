import { render } from "./Projects.vue?vue&type=template&id=c46e049c"
import script from "./Projects.vue?vue&type=script&lang=ts"
export * from "./Projects.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QPageContainer,QHeader,QToolbar,QSpace,QPage,QCard,QCardSection,QIcon});qInstall(script, 'directives', {Ripple});
