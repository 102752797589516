<template>
  <q-layout v-if="!hidePage">
    <q-page-container>
      <q-header class="bg-dark q-pa-md">
        <q-toolbar class="q-py-md">
          <AppLogo />
          <q-space />
          <AppAccountDropdown />
        </q-toolbar>
      </q-header>
      <q-page class="flex justify-center items-center q-pa-sm bg-dark">
        <transition
          enter-active-class="animated animate__fadeInDown"
          leave-active-class="animated animate__fadeOutDown"
          mode="out-in"
          :duration="140"
        >
          <div class="column q-pa-md">
            <div class="row q-mb-xl">
              <div class="bg-primary q-mr-md" style="width: 4px"></div>
              <div class="text-white">
                <div class="text-h5">Projekt</div>
                <div class="text-grey-6">Välj projekt för att fortsätta</div>
              </div>
            </div>
            <div class="row">
              <q-card
                @click="openProject(project.name)"
                v-ripple
                square
                v-for="(project, $i) in projects"
                :key="project.uuid"
                style="width: 250px"
                class="shadow-14 cursor-pointer q-mb-sm"
                :class="[
                  $i !== projects.length - 1 && 'q-mr-sm',
                  hasMasterProject(project.name) && 'bg-grey-9 text-white',
                ]"
              >
                <q-card-section
                  class="text-caption text-bold text-subtitle1 text-uppercase"
                >
                  <q-icon
                    class="q-mr-sm"
                    v-if="hasMasterProject(project.name)"
                    name="mdi-account-supervisor"
                  />
                  {{ project.description }}
                </q-card-section>
              </q-card>
            </div>
          </div>
        </transition>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useProfile } from '@/composable/useProfile'
import { routerPush } from '@/router'
import AppLogo from '@/components/AppLogo.vue'
import AppAccountDropdown from '@/components/AppAccountDropdown.vue'
import { onBeforeRouteLeave } from 'vue-router'
import { getDefaultRoute } from '@/router/priorityDefaultRouteFromProject'

export default defineComponent({
  name: 'Projects',

  components: {
    AppLogo,
    AppAccountDropdown,
  },

  setup() {
    const { projects, profile } = useProfile()
    const hidePage = ref(true)

    // Reload page to reset state between projects
    onBeforeRouteLeave((to) => {
      window.location.pathname = to.path
    })

    //  redirect to project if only 1
    if (projects.value.length === 1) {
      openProject(projects.value[0].name)
    } else {
      hidePage.value = false
    }

    function openProject(project: string) {
      if (hasMasterProject(project)) {
        routerPush('masterdata', { project })
      } else {
        if (!profile.value) {
          throw new Error('Profile permissions not found')
        }
        routerPush(getDefaultRoute(profile.value.permissions[project]), {
          project,
        })
      }
    }

    function hasMasterProject(projectName: string) {
      return projectName === 'master'
    }

    return { openProject, projects, hasMasterProject, hidePage }
  },
})
</script>
