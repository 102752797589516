
import { defineComponent, ref } from 'vue'
import { useProfile } from '@/composable/useProfile'
import { routerPush } from '@/router'
import AppLogo from '@/components/AppLogo.vue'
import AppAccountDropdown from '@/components/AppAccountDropdown.vue'
import { onBeforeRouteLeave } from 'vue-router'
import { getDefaultRoute } from '@/router/priorityDefaultRouteFromProject'

export default defineComponent({
  name: 'Projects',

  components: {
    AppLogo,
    AppAccountDropdown,
  },

  setup() {
    const { projects, profile } = useProfile()
    const hidePage = ref(true)

    // Reload page to reset state between projects
    onBeforeRouteLeave((to) => {
      window.location.pathname = to.path
    })

    //  redirect to project if only 1
    if (projects.value.length === 1) {
      openProject(projects.value[0].name)
    } else {
      hidePage.value = false
    }

    function openProject(project: string) {
      if (hasMasterProject(project)) {
        routerPush('masterdata', { project })
      } else {
        if (!profile.value) {
          throw new Error('Profile permissions not found')
        }
        routerPush(getDefaultRoute(profile.value.permissions[project]), {
          project,
        })
      }
    }

    function hasMasterProject(projectName: string) {
      return projectName === 'master'
    }

    return { openProject, projects, hasMasterProject, hidePage }
  },
})
