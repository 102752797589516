import { AppRouteNames } from '.'

interface DefaultRoutes {
  [permission: string]: AppRouteNames
}

const defaultRoutesByPermission: DefaultRoutes = {
  'ui.navigation.event': 'events',
  'ui.navigation.deviation.vehicle': 'vehicle-deviations',
  'ui.navigation.deviation.cancel': 'cancel-deviations',
  'ui.navigation.deviation.delay': 'delay-deviations',
  'ui.navigation.deviation.crew': 'crew-deviations',
  'ui.navigation.deviation.disruption': 'disruption-deviations',
  'ui.navigation.vehicleWithdrawal': 'vehicle-withdrawal',
  'ui.navigation.dailyReport': 'daily-report',
  'ui.navigation.dailyReportExternal': 'daily-report-external',
  'ui.navigation.tools.plannedCancellation': 'canceled-tomorrow',
  'ui.navigation.tools.deviatingVehicleComposition':
    'deviating-vehicle-composition',
  'ui.navigation.masterdata': 'masterdata',
  'ui.navigation.withdrawalDashboard': 'vehicle-availability',
}

export const getDefaultRoute = (permissions: string[]): AppRouteNames => {
  let foundRoute: AppRouteNames = 'projects'
  for (const [key, value] of Object.entries(defaultRoutesByPermission)) {
    if (permissions.some((permission) => permission === key)) {
      foundRoute = value
      break
    }
  }

  return foundRoute
}
